import {
  handleQueryResolve
} from '../utils'

export default function (patientID, drFirstName, drLastName, cpso) {
  if (patientID) {
    const where = []
    const attrs = []
    if (Array.isArray(patientID)) {
      where.push(`PatientID IN (${patientID.join(',')})`)
    } else {
      attrs.push({
        param: 'PatientID',
        type: 'sql.Int',
        value: patientID, // to support like
      })
      where.push('PatientID = @PatientID')
    }

    if (drFirstName) {
      attrs.push({
        param: 'drFirstName',
        type: 'sql.VarChar(50)',
        value: `%${drFirstName}%`, // to support like
      })

      where.push('DoctorFirstName LIKE @drFirstName')
    }

    if (drLastName) {
      attrs.push({
        param: 'drLastName',
        type: 'sql.VarChar(50)',
        value: `%${drLastName}%`, // to support like
      })

      where.push('DoctorLastName LIKE @drLastName')
    }

    if (cpso) {
      attrs.push({
        param: 'cpso',
        type: 'sql.VarChar(50)',
        value: `%${cpso}%`, // to support like
      })

      where.push('doctor.ID LIKE @cpso')
    }

    const query = `
SELECT DISTINCT
"d"."LastName" as DoctorLastName,
"d"."FirstName" as DoctorFirstName,
"a"."Phone" as DoctorPhone,
"a"."Line1" as DoctorAddress,
"a"."Fax" as DoctorFax,
"a"."CityName" as DoctorCity,
"a"."ProvinceName" as DoctorProvince,
"a"."PostalCode" as DoctorPostal,
"dp"."Description",
"d"."Id",
"d"."PrescriberId" as CPSO,
"d"."PrescriberId"+"a"."Phone"+"a"."Fax"  as DoctorSelectId,
"DBA"."Prescription"."PatientId" as PatientID,
COUNT("d"."PrescriberId") as RXperProvider
FROM "DBA"."Doctor" AS "d" LEFT OUTER JOIN "DBA"."DoctorPractice" AS "dp" ON "d"."Practicecode" = "dp"."code",
"DBA"."AddressRole" AS "ar",
"DBA"."Address" AS "a",
"DBA"."Doctor" AS "d" INNER JOIN "DBA"."Prescription" ON "d"."Id" = "DBA"."Prescription"."DoctorId"
WHERE "ar"."EntityId" = "d"."Id" AND "ar"."AddressId" = "a"."Id" AND "ar"."EntityCode" = 'DOC' AND "DBA"."Prescription"."FillDate" > DATEADD(year, -1, GetDate())    AND
${where.join(' AND ')}
GROUP BY
"d"."LastName",
"d"."FirstName",
"a"."Phone",
"a"."Line1",
"a"."Fax",
"a"."CityName",
"a"."ProvinceName",
"a"."PostalCode",
"dp"."Description",
"d"."Id",
"d"."PrescriberId",
PatientID
  ORDER BY RXperProvider DESC
`

    // console.log(query)

    return this.query(query, attrs).then(handleQueryResolve)
  }
}
